<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-md-12" v-if="$parent.odometros.id">
              <div class="position-relative p-3 m-2 bg-light">
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-primary">Odómetros</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div
                        class="col-sm-6 d-flex align-items-stretch flex-column"
                        v-if="$parent.odometros.inicial"
                      >
                        <div class="card bg-white d-flex flex-fill">
                          <div class="card-header text-muted border-bottom-0">
                            Odómetro Inicial
                          </div>
                          <div class="card-body pt-0">
                            <div class="row">
                              <div class="col-md-6">
                                <h3 class="lead">
                                  <b>{{ $parent.odometros.inicial }}</b>
                                </h3>
                              </div>
                              <div
                                class="col-md-8 text-center"
                                v-if="$parent.odometros.link_ini"
                              >
                                <img
                                  @click="getImage($parent.odometros.link_ini)"
                                  :src="uri_docs + $parent.odometros.link_ini"
                                  alt="Odómetro Inicial"
                                  class="img-bordered img-rounded img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-6 d-flex align-items-stretch flex-column"
                        v-if="$parent.odometros.final"
                      >
                        <div class="card bg-white d-flex flex-fill">
                          <div class="card-header text-muted border-bottom-0">
                            Odómetro Final
                          </div>
                          <div class="card-body pt-0">
                            <div class="row">
                              <div class="col-md-5">
                                <h3 class="lead">
                                  <b>{{ $parent.odometros.final }}</b>
                                </h3>
                              </div>
                              <div
                                class="col-md-8 text-center"
                                v-if="$parent.odometros.link_fin"
                              >
                                <img
                                  @click="getImage($parent.odometros.link_fin)"
                                  :src="uri_docs + $parent.odometros.link_fin"
                                  alt="Odómetro Final"
                                  class="img-bordered img-rounded img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="position-relative p-3 m-2 bg-light">
                        <div class="ribbon-wrapper ribbon-lg">
                          <div class="ribbon bg-primary">Diferencia</div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div
                                class="col-sm-12 d-flex align-items-stretch flex-column"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div
                                    class="card-header text-muted border-bottom-0 text-center"
                                  >
                                    Diferencia Odómetros
                                  </div>
                                  <div class="card-body pt-0">
                                    <div class="row">
                                      <div class="col-md-12 text-center">
                                        <h3 class="lead">
                                          <b>{{
                                            (
                                              $parent.odometros.final -
                                              $parent.odometros.inicial
                                            ).toFixed(2)
                                          }}</b>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="col-md-12 d-flex align-items-stretch flex-column"
            >
              <div class="small-box bg-info" style="min-height: 100px">
                <div class="inner">
                  <h3>No hay Odómetros</h3>
                </div>
                <div class="icon">
                  <i class="far fa-eye-slash"></i>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="$parent.turno.km_ws">
              <div class="position-relative p-3 m-2 bg-light">
                <div class="ribbon-wrapper ribbon-lg">
                  <div class="ribbon bg-primary">km</div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div
                        class="col-sm-12 d-flex align-items-stretch flex-column"
                      >
                        <div class="card bg-white d-flex flex-fill">
                          <div
                            class="card-header text-muted border-bottom-0 text-center"
                          >
                            Kilómetros Webservices
                          </div>
                          <div class="card-body pt-0">
                            <div class="row">
                              <div class="col-md-12 text-center">
                                <h3 class="lead">
                                  <b>{{ $parent.turno.km_ws }}</b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="col-md-12 d-flex align-items-stretch flex-column"
            >
              <div class="small-box bg-info" style="min-height: 100px">
                <div class="inner">
                  <h3>No hay Kilómetros Webservices</h3>
                </div>
                <div class="icon">
                  <i class="far fa-eye-slash"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TifTurnoOdometro",
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getImage(url) {
      window.open(this.uri_docs + url, "_blank");
    },
  },
};
</script>
